<template>
  <div class="main-content-wrap import-list">

    <!-- 头部 -->
    <paymentTitle></paymentTitle>

    <!-- 基本情况 -->
    <div class="Basic_Information_content">
      <div class="flex_direction">
        <div class="Basic_Information">
          <div class=" Basic_Information_title">
            Basic Information
          </div>

          <div class="Basic_Information_subject">
            <div class="Basic_Information_subject_size">
              Receiving information
            </div>

            <div class="Basic_Information_subject_address">
              <div class="">
                No address
              </div>
              <div>
                <div @click="address_cli" style="border:none" class="address_input">
                  {{address}}
                </div>
              </div>

              <div class=" Add_address_cli" @click="Add_address_cli">
                Add address
              </div>

              <!-- 地址下拉列表 -->
              <div class="Address_drop_down_list" v-show="drop_down_show">
                <div class="Address_selection_content" v-for="item in list" :key="item.id">
                  <div class="Address_selection_content_radio">
                    <el-radio-group v-model="Address_selection_radio" @change="Address_selection_Change">
                      <el-radio :label="item.id">{{''}}</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="Address_selection">
                    <div class="Address_selection_name">
                      {{item.name}}
                    </div>
                    <div class="Address_selection_address">
                      {{item.address}}
                    </div>
                  </div>
                </div>

                <div class="add_address_btn">

                  <div class="add_address_btn_size" @click="Show_pop_ups">
                    + Add address
                  </div>

                </div>

              </div>

            </div>

            <div class="Shipping_method">
              <div class="Shipping_method_size">
                Shipping method
              </div>

              <div class="Shipping_method_content">
                <el-radio v-model="Shipping_method" label="1"> <span style="color:#1A1A1A"> 6 - 10 business days due to
                    COVID-19</span></el-radio>

                <div class="Free">
                  Free
                </div>
              </div>
            </div>

          </div>

        </div>

        <div class="Payment_content">
          <div class="Payment_content_title">
            <div class="Payment">
              Payment
            </div>
            <div class="Payment_tip">
              All transactions are secure and encrypted.
            </div>
          </div>
        </div>

        <!-- <div>
          123213
        </div> -->
      </div>


      <!-- 右侧产品清单 -->
      <Productlist></Productlist>

    </div>


    <!-- 添加地址 -->
    <el-dialog title="" :visible.sync="add_address_dialogVisible" width="30%" :before-close="handleClose">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-input v-model="First_name" placeholder="First name"></el-input>
        </el-col>
        <el-col :span="12">
          <el-input v-model="Last_name" placeholder="Last name"></el-input>
        </el-col>
      </el-row>

      <el-row class="mt_16">
        <el-col :span="24">
          <el-input v-model="Company" placeholder="Company"></el-input>
        </el-col>
      </el-row>

      <el-row class="mt_16">
        <el-col :span="24">
          <el-input v-model="Phone" placeholder="Phone"></el-input>
        </el-col>
      </el-row>

      <el-row class="mt_16">
        <el-col :span="24">
          <el-input v-model="Address" placeholder="Address"></el-input>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="mt_16">
        <el-col :span="12">
          <el-input v-model="City" placeholder="First City"></el-input>
        </el-col>
        <el-col :span="12">
          <el-input v-model="ZipCode" placeholder="Zip Code"></el-input>
        </el-col>
      </el-row>

      <el-row class="mt_16">
        <el-col :span="24">
          <el-select style="width:100%" v-model="Country_value" placeholder="请选择">
            <el-option v-for="item in Country_options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row class="mt_16">
        <el-col :span="24">
          <el-select style="width:100%" v-model="Province_value" placeholder="请选择">
            <el-option v-for="item in Province_options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row class="mt_16">
        <el-col :span="24">
          <el-checkbox v-model="checked">Set as default payment method</el-checkbox>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button style="width:100%" type="primary" @click="add_address_dialogVisible_btn">Save</el-button>
      </span>
    </el-dialog>



  </div>
</template>
<script>
  import paymentTitle from './components/payment_title'
  import Productlist from './components/Product_list'
  export default {
    components: {
      paymentTitle,
      Productlist
    },
    data() {
      return {
        // 地址
        address: 'Please enter',
        // 运送方式
        Shipping_method: '1',

        drop_down_show: false,

        Address_selection_radio: '1',

        list: [{
          id: 1,
          name: '1111',
          address: '2222'
        }, {
          id: 2,
          name: '2222',
          address: '333333'
        }],
        // 新增地址弹框
        add_address_dialogVisible: false,
        // 名
        First_name: '',
        // 姓
        Last_name: '',
        // 
        Company: '',

        // 手机号
        Phone: '',

        // 地址
        Address: '',

        // 城市
        City: '',

        // 
        ZipCode: '',

        // 
        Country_options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        Country_value: '',

        // 
        Province_options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        Province_value: '',

        checked: false



      }
    },
    methods: {
      handleClose(done) {
        done();
      },


      Add_address_cli() {
        this.add_address_dialogVisible = true
      },


      address_cli() {
        console.log(9909)
        this.drop_down_show = !this.drop_down_show
      },

      Address_selection_Change(val) {
        console.log(val)
        this.drop_down_show = false

        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].id == val) {
            this.address = this.list[i].address
            break
          }
        }
        // this.address = val
      },


      // 添加地址按钮
      Show_pop_ups() {
        this.add_address_dialogVisible = true
      },

      add_address_dialogVisible_btn() {
        this.add_address_dialogVisible = false
      }
    },
  }

</script>
<style lang="scss" scoped>
  .mt_16 {
    margin-top: 16px;
  }

  .import-list {
    padding-top: 25px;
  }

  .main-content-wrap {
    width: 1300px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .Basic_Information_content {
    display: flex;

    .flex_direction {
      display: flex;
      flex-direction: column;

      .Basic_Information {
        width: 900px;
        background: #ffffff;
        border: 1px solid #dedede;

        .Basic_Information_title {
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          color: #000000;
          margin-top: 15px;
          margin-left: 24px;
          margin-bottom: 14px;
        }

        .Basic_Information_subject {
          border-top: 1px solid #dedede;
          padding-left: 24px;
          padding-right: 24px;

          .Basic_Information_subject_size {
            margin-top: 16px;
          }

          .Basic_Information_subject_address {
            height: 61px;
            border: 1px solid #dedede;
            padding-left: 11px;
            padding-top: 8px;
            position: relative;

            .address_input {
              margin-top: 2%;
              color: #D3D3D3;

            }

            .Add_address_cli {
              position: absolute;
              right: 1%;
              top: 40%;
              color: #FF4D4F;
              font-size: 14px;
              cursor: pointer
            }


            .Address_drop_down_list {
              width: 850px;
              // height: 200px;
              border: 1px solid #dedede;
              background: white;
              z-index: 999;
              position: absolute;
              right: -0.2%;
              top: 100%;

              .Address_selection_content {
                background: #f7f7f7;
                margin-top: 16px;
                margin-left: 23px;
                margin-right: 23px;
                margin-bottom: 16px;
                display: flex;
                height: 61px;

                .Address_selection_content_radio {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 9px;
                }

                .Address_selection {
                  display: flex;
                  flex-direction: column;
                  margin-left: 16px;

                  .Address_selection_name {
                    font-size: 14px;
                    font-family: PingFangSC, PingFangSC-Medium;
                    font-weight: bold;
                    color: #000000;
                    margin-top: 16px;

                  }

                  .Address_selection_address {
                    margin-top: 8px;
                    font-size: 14px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                  }
                }
              }


              .add_address_btn {
                display: flex;
                align-content: center;
                justify-content: center;
                height: 40px;
                border: 2px dashed #D3D3D3;
                margin-top: 16px;
                margin-left: 23px;
                margin-right: 23px;
                margin-bottom: 16px;
                font-size: 13px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                margin-top: 10px;


                .add_address_btn_size {
                  line-height: 40px;
                }
              }
            }
          }

          .Shipping_method {
            margin-top: 16px;

            .Shipping_method_content {
              background: #ffffff;
              border: 1px solid #dedede;
              padding-top: 17px;
              padding-left: 11px;
              padding-bottom: 16px;
              position: relative;
              z-index: 0;
              margin-top: 4px;
              margin-bottom: 14px;

              .Free {
                position: absolute;
                right: 1%;
                top: 40%;
                color: #52C41A;
              }
            }
          }

        }


      }
    }






  }

  .Payment_content {
    margin-top: 24px;
    border: 1px solid #dedede;
    background: white;
    padding-left: 24px;
    padding-top: 15px;
    padding-bottom: 14px;

    .Payment_content_title {
      display: flex;

      .Payment{
        font-weight: bold;
      }

      .Payment_tip {
        margin-left: 16px;
      }

    }
  }

</style>
