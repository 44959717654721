<template>
  <div>
    
      <div class="Product_list">
        <div class="Product_list_title">
          Product list
        </div>

        <div class="Product_list_content">
          <div class="Product_list_content_shop">
            <div class="Product_list_content_shop_img">
              <img src="" alt="">
            </div>
            <div class="Product_list_content_shop_information">
              <div class="Product_list_content_shop_information_title">
                Phi Villa 3-Piece Rattan Outdoor Sectional Sofa - Blue
              </div>

              <div class="Product_list_content_shop_information_price">
                $ 847.00
              </div>
            </div>
          </div>

          <div class="Product_list_content_shop">
            <div class="Product_list_content_shop_img">
              <img src="" alt="">
            </div>
            <div class="Product_list_content_shop_information">
              <div class="Product_list_content_shop_information_title">
                Phi Villa 3-Piece Rattan Outdoor Sectional Sofa - Blue
              </div>

              <div class="Product_list_content_shop_information_price">
                $ 847.00
              </div>
            </div>
          </div>

            <div class="Product_list_content_shop">
            <div class="Product_list_content_shop_img">
              <img src="" alt="">
            </div>
            <div class="Product_list_content_shop_information">
              <div class="Product_list_content_shop_information_title">
                Phi Villa 3-Piece Rattan Outdoor Sectional Sofa - Blue
              </div>

              <div class="Product_list_content_shop_information_price">
                $ 847.00
              </div>
            </div>
          </div>


        </div>

      </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>

    .Product_list {
      width: 376px;
      margin-left: 24px;
      background: white;
      border: 1px solid #dedede;

      .Product_list_title {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        color: #000000;
        padding-top: 15px;
        padding-left: 24px;
        padding-bottom: 14px;
      }

      .Product_list_content {
        display: flex;
        flex-direction: column;

        .Product_list_content_shop {
          display: flex;
          border-top: 1px solid #dedede;

          .Product_list_content_shop_img {
            width: 84px;
            height: 84px;
            background: blue;
            margin-left: 24px;
            margin-top: 18px;
            margin-bottom: 14px;
          }

          .Product_list_content_shop_information {
            display: flex;
            flex-direction: column;
            margin-top: 18px;
            margin-left: 16px;

            .Product_list_content_shop_information_title {
              flex: 7;
              width: 240px;
              word-wrap: break-word;
              word-break: break-all;
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              color: #000000;
            }

            .Product_list_content_shop_information_price {
              flex: 3;
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              color: #000000;
            }
          }
        }
      }
    }
</style>
