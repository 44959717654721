<template>
  <div>
    <div class="Payment_content">
      <div class="payment_title">
        Payment
      </div>
      <div class="payment_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{path:'/'}">Cart</el-breadcrumb-item>
          <el-breadcrumb-item>Information</el-breadcrumb-item>
          <el-breadcrumb-item>Shipping</el-breadcrumb-item>
          <el-breadcrumb-item :to="{path:'/Payment_information'}">Payment</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  </div>
</template>
<script>
  export default {

  }

</script>
<style lang="scss" scoped>
  .Payment_content {
    display: flex;
    flex-direction: column;

    .payment_title {
      font-size: 26px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #000000;
    }

    .payment_breadcrumb {
      margin-top: 15px;
    }

  }

</style>
